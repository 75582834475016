import React from "react"
import { motion } from 'framer-motion';

let easing = [0.1575, 0.85, 0.42, 0.96];

const slideDownAnimation = {
  enter: { y: 0, opacity: 1, transition: { duration: 1.8, ease: easing } },
  exit: { y: -50, opacity: 0, transition: { duration: 1.8, ease: easing } }
}

const zoomInAnimation = {
  enter: { y: 0, scale: 1, opacity: 1, transition: { duration: 0.8, ease: easing } },
  exit: { y: 0, scale: 0.8, opacity: 0, transition: { duration: 0.8, ease: easing } }
}

function HeaderComponent ({ images, siteTitle }) {
  const [lastYPos, setLastYPos] = React.useState(0);

  const [shouldShowSection, setShouldShowSection] = React.useState(false);

  React.useEffect(() => {
    function handleScroll() {
      const yPos = window.scrollY;

      let shouldShowSection;

      let sectionPos = document.querySelector("#header").getBoundingClientRect();

      if (sectionPos.top < 5) {
        shouldShowSection = true;
      }

      setShouldShowSection(shouldShowSection);
      setLastYPos(yPos);
    }

    window.addEventListener("scroll", handleScroll, false);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [lastYPos]);

  return (
    <>
      <motion.div initial="exit" animate="enter" exit="exit" className="container-inner">
        <motion.header variants={slideDownAnimation} id="header" className="header">
          <motion.h2 initial={slideDownAnimation.enter} animate={shouldShowSection ? slideDownAnimation.exit : slideDownAnimation.enter}>Trading journal for the crypto space</motion.h2>
          <motion.div initial={slideDownAnimation.enter} animate={shouldShowSection ? slideDownAnimation.exit : slideDownAnimation.enter} className="subtitle">
            MyCryptoJournal is a platform that connects to your favorite exchange and empowers you to import, track and analyze your trades. The easy way.
          </motion.div>
          <motion.div initial={slideDownAnimation.enter} animate={shouldShowSection ? slideDownAnimation.exit : slideDownAnimation.enter} className="buttons">
            <a href="https://app.mycryptojournal.io/register"><button className="btn btn-primary">Sign up</button></a>
          </motion.div>
        </motion.header>
      </motion.div>
    </>
  )
}

export default HeaderComponent;
