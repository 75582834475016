import React, { Component } from "react";
import Carousel from "react-spring-3d-carousel";
import uuid from 'react-uuid'
import { config } from "react-spring";
import Swiper from 'react-id-swiper';

export default class Example extends Component {
  constructor(props) {
    super(props);
  }
  
  state = {
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: false,
    config: config.gentle
  };

  onChangeInput = e => {
    this.setState({
      [e.target.name]: parseInt(e.target.value, 10) || 0
    });
  };

  render() {
    const params = {
      slidesPerView: '1',
      spaceBetween: 50
    }

    const { images } = this.props;

    const slides = [
      {
        key: uuid(),
        content: <img className="gallery-slide-wrapper" src={images[0].childImageSharp.fluid.src} />
      },
      {
        key: uuid(),
        content: <img className="gallery-slide-wrapper" src={images[1].childImageSharp.fluid.src} />
      },
      {
        key: uuid(),
        content: <img className="gallery-slide-wrapper" src={images[2].childImageSharp.fluid.src} />
      },
    ].map((slide, index) => {
      return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
    });
    
  
    return (
      <>
        <div className="gallery-wrapper container-inner">
          {typeof window !== 'undefined' &&
            <Carousel
            slides={slides}
            goToSlide={this.state.goToSlide}
            offsetRadius={this.state.offsetRadius}
            showNavigation={this.state.showNavigation}
            animationConfig={this.state.config}
          />
          }
        </div>
        <div className="gallery-wrapper--mobile mt-8">
          <Swiper {...params}>
            <img className="gallery-slide-wrapper" src={images[0].childImageSharp.fluid.src} />
            <img className="gallery-slide-wrapper" src={images[1].childImageSharp.fluid.src} />
            <img className="gallery-slide-wrapper" src={images[2].childImageSharp.fluid.src} />
          </Swiper>
        </div>
      </>
    );
  }
}
