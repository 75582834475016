import React from "react"
import GalleryCarousel from "../components/Header/GalleryCarousel";
import { StaticQuery, graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/Header"
import Usps from "../components/Usps.js"
import WhosThisFor from "../components/WhosThisFor"
import Features from "../components/Features"
import ExchangeIntegrations from "../components/ExchangeIntegrations"
import FaqComponent from "../components/FaqComponent"
import { motion } from 'framer-motion';

let easing = [0.1575, 0.85, 0.42, 0.96];

const slideDownAnimation = {
  enter: { y: 0, opacity: 1, transition: { duration: 1.8, ease: easing } },
  exit: { y: -50, opacity: 0, transition: { duration: 1.8, ease: easing } }
}

const zoomInAnimation = {
  enter: { y: 0, scale: 1, opacity: 1, transition: { duration: 0.8, ease: easing } },
  exit: { y: 0, scale: 0.8, opacity: 0, transition: { duration: 0.8, ease: easing } }
}

function IndexPageComponent ({images}) {
  return (
    <Layout>
      <SEO title="Home" />
      <section className="section section--header">
        <Header/>
        <motion.div initial="exit" animate="enter" exit="exit">
          <motion.div variants={zoomInAnimation}>
            <motion.div>
              <GalleryCarousel images={images}/>
            </motion.div>
          </motion.div>
        </motion.div>
      </section>
      
      <section className="section section--usps container-inner">
        <Usps/>
      </section>

      <section id="whosthisfor" className="section section--whos-this-for container-inner">
        <WhosThisFor/>
      </section>

      <section className="section section--features container-inner">
        <Features/>
      </section>

      <section className="section section--integrations container-inner">
        <ExchangeIntegrations/>
      </section>

      <section className="section section--faq container-inner">
        <FaqComponent/>
      </section>
    </Layout>
  )
}

export default function IndexPage() {
  return (
    <StaticQuery
      query={graphql`
        query {
          appImage1: file(relativePath: { eq: "app-img-1.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          appImage2: file(relativePath: { eq: "app-img-2.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          appImage3: file(relativePath: { eq: "app-img-3.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={(data) => (
        <IndexPageComponent images={[data.appImage1, data.appImage2, data.appImage3]}/>
      )}
    />
)
}