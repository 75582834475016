import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';
import { motion } from 'framer-motion';

let easing = [0.1575, 0.85, 0.42, 0.96];

const zoomInAnimation1 = {
  enter: { y: 0, scale: 1, opacity: 1, transition: { duration: 0.8, ease: easing } },
  exit: { y: 0, scale: 0.8, opacity: 0, transition: { duration: 0.8, ease: easing } }
}

const zoomInAnimation2 = {
  enter: { y: 0, scale: 1, opacity: 1, transition: { duration: 0.8, ease: easing, delay: 0.1 } },
  exit: { y: 0, scale: 0.8, opacity: 0, transition: { duration: 0.8, ease: easing, delay: 0.1 } }
}

const zoomInAnimation3 = {
  enter: { y: 0, scale: 1, opacity: 1, transition: { duration: 0.8, ease: easing, delay: 0.2 } },
  exit: { y: 0, scale: 0.8, opacity: 0, transition: { duration: 0.8, ease: easing, delay: 0.2 } }
}

const UspsComponent  = props => {
  const [lastYPos, setLastYPos] = React.useState(0);

  const [shouldShowSection, setShouldShowSection] = React.useState(false);

  React.useEffect(() => {
    function handleScroll() {
      const yPos = window.scrollY;

      let shouldShowSection;

      let sectionPos = document.querySelector("#usps").getBoundingClientRect();

      if (sectionPos.top < 600) {
        shouldShowSection = true;
      }

      if (sectionPos.top < -100) {
        shouldShowSection = false;
      }

      setShouldShowSection(shouldShowSection);
      setLastYPos(yPos);
    }

    window.addEventListener("scroll", handleScroll, false);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [lastYPos]);

  const { icons } = props;
  return (
    <motion.div initial="exit" animate="enter" exit="exit" id="usps" className="usps-wrapper">
      <motion.div variants={zoomInAnimation1} className="usp">
        <motion.div initial={zoomInAnimation1.enter} animate={shouldShowSection ? zoomInAnimation1.enter : zoomInAnimation1.exit}>
          <div className="icon mx-auto">
            <img className="normal" src={icons[0].childImageSharp.fluid.src} />
            <img className="hover" src={icons[1].childImageSharp.fluid.src} />
          </div>
          <h3>Connect with exchanges</h3>
        </motion.div>
      </motion.div>

      <motion.div variants={zoomInAnimation2} className="usp">
        <motion.div initial={zoomInAnimation2.enter} animate={shouldShowSection ? zoomInAnimation2.enter : zoomInAnimation2.exit}>
          <div className="icon mx-auto">
            <img className="normal" src={icons[3].childImageSharp.fluid.src} />
            <img className="hover" src={icons[2].childImageSharp.fluid.src} />
          </div>
          <h3>Smart grouping of trades</h3>
        </motion.div>
      </motion.div>

      <motion.div variants={zoomInAnimation3} className="usp">
        <motion.div initial={zoomInAnimation3.enter} animate={shouldShowSection ? zoomInAnimation3.enter : zoomInAnimation3.exit}>
          <div className="icon mx-auto">
            <img className="normal" src={icons[5].childImageSharp.fluid.src} />
            <img className="hover" src={icons[4].childImageSharp.fluid.src} />
          </div>
          <h3>Advanced trading features</h3>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

export default function Usps() {
  return (
    <StaticQuery
      query={graphql`
        query {
          connectIcon: file(relativePath: { eq: "connect_icon-1.png" }) {
            childImageSharp {
              fluid(maxWidth: 140) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          connectIconH: file(relativePath: { eq: "connect_icon_h-1.png" }) {
            childImageSharp {
              fluid(maxWidth: 140) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          advancedFeaturesIcon: file(relativePath: { eq: "advanced_features-1.png" }) {
            childImageSharp {
              fluid(maxWidth: 140) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          advancedFeaturesIconH: file(relativePath: { eq: "advanced_features_h-1.png" }) {
            childImageSharp {
              fluid(maxWidth: 140) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          smartGroupingIcon: file(relativePath: { eq: "smart_grouping-1.png" }) {
            childImageSharp {
              fluid(maxWidth: 140) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          smartGroupingIconH: file(relativePath: { eq: "smart_grouping_h-1.png" }) {
            childImageSharp {
              fluid(maxWidth: 140) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={(data) => (
        <UspsComponent icons={[data.connectIcon, data.connectIconH, data.smartGroupingIconH, data.smartGroupingIcon, data.advancedFeaturesIconH, data.advancedFeaturesIcon]}/>
      )}
    />
)
}