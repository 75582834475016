import React from 'react';
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';
import { motion } from 'framer-motion';

let easing = [0.1575, 0.85, 0.42, 0.96];

const fadeInAnimation = {
  enter: { y: 0, opacity: 1, transition: { duration: 0.8, ease: easing } },
  exit: { y: 0, opacity: 0, transition: { duration: 0.8, ease: easing } }
}

const slideRightAnimation = {
  enter: { x: 0, y: 0, opacity: 1, transition: { duration: 1.3, ease: easing } },
  exit: { x: -50, y: 0, opacity: 0, transition: { duration: 1.3, ease: easing } }
}

const slideLeftAnimation = {
  enter: { x: 0, y: 0, opacity: 1, transition: { duration: 1.3, ease: easing } },
  exit: { x: 50, y: 0, opacity: 0, transition: { duration: 1.3, ease: easing } }
}

const FeaturesComponent = props => {
  const [lastYPos, setLastYPos] = React.useState(0);

  const [shouldShowSection1, setShouldShowSection1] = React.useState(false);
  const [shouldShowSection2, setShouldShowSection2] = React.useState(false);
  const [shouldShowSection3, setShouldShowSection3] = React.useState(false);

  const { images } = props;

  React.useEffect(() => {
    function handleScroll() {
      const yPos = window.scrollY;

      let shouldShowSection1;
      let shouldShowSection2;
      let shouldShowSection3;

      let sectionPos1 = document.querySelector("#feature-1").getBoundingClientRect();
      let sectionPos2 = document.querySelector("#feature-2").getBoundingClientRect();
      let sectionPos3 = document.querySelector("#feature-3").getBoundingClientRect();

      if (sectionPos1.top < 800) {
        shouldShowSection1 = true;
      }

      if (sectionPos1.top < -100) {
        shouldShowSection1 = false;
      }

      if (sectionPos2.top < 600) {
        shouldShowSection2 = true;
      }

      if (sectionPos2.top < -100) {
        shouldShowSection2 = false;
      }

      if (sectionPos3.top < 600) {
        shouldShowSection3 = true;
      }

      if (sectionPos3.top < -500) {
        shouldShowSection3 = false;
      }

      setShouldShowSection1(shouldShowSection1);
      setShouldShowSection2(shouldShowSection2);
      setShouldShowSection3(shouldShowSection3);
      setLastYPos(yPos);
    }

    window.addEventListener("scroll", handleScroll, false);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [lastYPos]);

  return (
    <motion.div initial="exit" animate="enter" exit="exit" id="features">
      <motion.div variants={fadeInAnimation} className="features-wrapper">
        <motion.div initial={slideRightAnimation.enter} animate={shouldShowSection1 ? slideRightAnimation.enter : slideRightAnimation.exit} id="feature-1" className="feature">
          <div className="image">
            <Img fluid={images[0].childImageSharp.fluid} />
          </div>
          <div className="content">
            <h2>Connect with exchanges</h2>
            <div className="description">Import trades directly from exchanges or just add them the old way.</div>
          </div>
        </motion.div>
        <motion.div initial={slideLeftAnimation.enter} animate={shouldShowSection2 ? slideLeftAnimation.enter : slideLeftAnimation.exit} id="feature-2" className="feature">
          <div className="image">
            <Img fluid={images[1].childImageSharp.fluid} />
          </div>
          <div className="content">
            <h2>Smart grouping of trades</h2>
            <div className="description">Automatically groups your partial trades to easily visualize and add trade management notes.</div>
          </div>
        </motion.div>
        <motion.div initial={slideRightAnimation.enter} animate={shouldShowSection3 ? slideRightAnimation.enter : slideRightAnimation.exit} id="feature-3" className="feature" className="feature">
          <div className="image">
            <Img fluid={images[2].childImageSharp.fluid} />
          </div>
          <div className="content">
            <h2>Advanced trading features</h2>
            <div className="description">Manage your trade using Stop Loss, Take Profit, RR, upload setups and more.</div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

export default function Features() {
  return (
    <StaticQuery
      query={graphql`
        query {
          appFeature1: file(relativePath: { eq: "feature-1.png" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          appFeature2: file(relativePath: { eq: "feature-2.png" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          appFeature3: file(relativePath: { eq: "feature-3.png" }) {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={(data) => (
        <FeaturesComponent images={[data.appFeature1, data.appFeature2, data.appFeature3]}/>
      )}
    />
)
}