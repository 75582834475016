import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import _ from 'lodash';
import { motion } from 'framer-motion';

let easing = [0.1575, 0.85, 0.42, 0.96];


const slideRightAnimation = {
  enter: { x: 0, y: 0, opacity: 1, transition: { duration: 1.3, ease: easing } },
  exit: { x: -50, y: 0, opacity: 0, transition: { duration: 1.3, ease: easing } }
}

const slideDownAnimation1 = {
  enter: { y: 0, opacity: 1, transition: { duration: 1.3, ease: easing } },
  exit: { y: -50, opacity: 0, transition: { duration: 1.3, ease: easing } }
}

const slideDownAnimation2 = {
  enter: { y: 0, opacity: 1, transition: { duration: 1.3, ease: easing, delay: 0.2 } },
  exit: { y: -50, opacity: 0, transition: { duration: 1.3, ease: easing, delay: 0.2 } }
}

const WhosThisFor = () => {
  const [lastYPos, setLastYPos] = React.useState(0);

  const [shouldShowSection, setShouldShowSection] = React.useState(false);
  const [swiperGood, updateSwiperGood] = useState(null);
  const [swiperNegative, updateSwiperNegative] = useState(null);
  const [swiperGoodIndex, setSwiperGoodIndex] = useState(0);
  const [swiperNegativeIndex, setSwiperNegativeIndex] = useState(0);

  React.useEffect(() => {
    function handleScroll() {
      const yPos = window.scrollY;

      let shouldShowSection;

      let sectionPos = document.querySelector("#whosthisfor").getBoundingClientRect();

      if (sectionPos.top < 800) {
        shouldShowSection = true;
      }

      if (sectionPos.top < -600) {
        shouldShowSection = false;
      }

      setShouldShowSection(shouldShowSection);
      setLastYPos(yPos);
    }

    window.addEventListener("scroll", handleScroll, false);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [lastYPos]);

  if (swiperGood) {
    swiperGood.on('slideChange', function() {
      setSwiperGoodIndex(swiperGood.activeIndex);
    })
  }

  if (swiperNegative) {
    swiperNegative.on('slideChange', function() {
      setSwiperNegativeIndex(swiperNegative.activeIndex);
    })
  }

  const slideTo = (swiper, index) => {
    if (swiper !== null) {
      swiper.slideTo(index);
    }
  }

  const generateBullets = (swiper, swiperType) => {
    let bullets = [];

    if (swiper) {
      _.map(swiper.slides, function(slide, key) {
        bullets.push(
          <span 
            className={`swiper-pagination-bullet pagination-${swiperType} ${(swiperType == 'good' ? swiperGoodIndex == key : swiperNegativeIndex == key) ? 'swiper-pagination-bullet-active' : ''}`}
            onClick={() => slideTo(swiper, key)}>
          </span>
        )
      })
    }

    return bullets;
  }

  const params = {
    slidesPerView: '1',
    breakpoints: {
      900: {
        spaceBetween: 60
      }
    }
  }
  
  return (
    <>
      <motion.div initial="exit" animate="enter" exit="exit" className="left-side">
        <motion.div variants={slideRightAnimation}>
          <motion.div initial={slideRightAnimation.enter} animate={shouldShowSection ? slideRightAnimation.enter : slideRightAnimation.exit}>
            <h2>Who's this for?</h2>
            <div className="title-content">
              Using a trading journal is one of the most impactful things you can to do become 
              and stay a profitable trader. It's what separates amateurs from pros. Reviewing 
              trades at the end of each week/month discovers and eliminates mistakes while insuring 
              consistency in profitable trading strategies
            </div>
          </motion.div>
        </motion.div>
      </motion.div>

      <motion.div initial="exit" animate="enter" exit="exit" className="right-side">
        <div className="side">
        <motion.div variants={slideDownAnimation1} className="good">
          <motion.div initial={slideDownAnimation1.enter} animate={shouldShowSection ? slideDownAnimation1.enter : slideDownAnimation1.exit}>
            <div className="tag-wrapper">
              <div className="tag positive">For you if</div>
              <div className="pagination">
                {generateBullets(swiperGood, 'good')}
              </div>
            </div>
            <Swiper {...params} getSwiper={updateSwiperGood}>
              <ul className="slide positive">
                <li><span>You already have a trading journal, but you're searching for a tool that will make data entry and visualisation easier</span></li>
                <li><span>You look at trading as a journey that will take many years</span></li>
              </ul>
              <ul className="slide positive">
                <li><span>You're constantly looking for ways to eliminate mistakes because you know that cost a lot of money in the long run</span></li>
                <li><span>You take trading seriously</span></li>
              </ul>
              <ul className="slide positive">
                <li><span>You want to know when and where your strategies are working and where you're losing money</span></li>
                <li><span>You're looking to be profitable consistently</span></li>
              </ul>
            </Swiper>
          </motion.div>
        </motion.div>
        </div>
        <div className="side">
        <motion.div variants={slideDownAnimation1} className="not-good">
          <motion.div initial={slideDownAnimation1.enter} animate={shouldShowSection ? slideDownAnimation1.enter : slideDownAnimation1.exit}>
            <div className="tag-wrapper">
              <div className="tag negative">Not for you if</div>
              <div className="pagination">
                {generateBullets(swiperNegative, 'negative')}
              </div>
            </div>
            <Swiper {...params} getSwiper={updateSwiperNegative}>
              <ul className="slide negative">
                <li><span>You think trading is a ticket to get rich quick</span></li>
                <li><span>You think trading is gambling</span></li>
              </ul>
              <ul className="slide negative">
                <li><span>You trade by feeling</span></li>
                <li><span>You feel logging your trades and results are a lot of work</span></li>
              </ul>
              <ul className="slide negative">
                <li><span>You trade while on the phone in the car on the way to work</span></li>
                <li><span>You trade for fun</span></li>
              </ul>
            </Swiper>
          </motion.div>
        </motion.div>
        </div>
      </motion.div>
    </>
  )
}

export default WhosThisFor;