import React from 'react';
import binance from '../images/binance.png';
import bitfinnex from '../images/bitfinnex.png';
import bitmex from '../images/bitmex.png';
import kucoin from '../images/kucoin.png';
import okex from '../images/okex.png';
import Swiper from 'react-id-swiper';
import { motion } from 'framer-motion';

let easing = [0.1575, 0.85, 0.42, 0.96];

const slideDownAnimation = {
  enter: { y: 0, opacity: 1, transition: { duration: 1.3, ease: easing } },
  exit: { y: -50, opacity: 0, transition: { duration: 1.3, ease: easing } }
}

const ExchangeIntegrations  = (props) => {
  const [lastYPos, setLastYPos] = React.useState(0);

  const [shouldShowSection, setShouldShowSection] = React.useState(false);

  React.useEffect(() => {
    function handleScroll() {
      const yPos = window.scrollY;

      let shouldShowSection;

      let sectionPos = document.querySelector("#integrations").getBoundingClientRect();

      if (sectionPos.top < 600) {
        shouldShowSection = true;
      }

      if (sectionPos.top < -100) {
        shouldShowSection = false;
      }

      setShouldShowSection(shouldShowSection);
      setLastYPos(yPos);
    }

    window.addEventListener("scroll", handleScroll, false);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [lastYPos]);

  const params = {
    slidesPerView: '1',
    spaceBetween: 60,
    breakpoints: {
      320: {
        slidesPerView: 2,
      },
      700: {
        slidesPerView: 3,
      },
      900: {
        slidesPerView: 4
      },
      1100: {
        slidesPerView: 5
      }
    }
  }

  return (
    <motion.div initial="exit" animate="enter" exit="exit" id="integrations">
      <motion.div variants={slideDownAnimation}>
        <motion.div initial={slideDownAnimation.enter} animate={shouldShowSection ? slideDownAnimation.enter : slideDownAnimation.exit}>
          <h2>Exchange integrations</h2>
          <div className="subtitle text-center">We support the following exchanges</div>
          <Swiper {...params} className="integrations--elements">
            <div className="element">
              <img src={binance}/>
            </div>

            <div className="element">
              <img src={bitfinnex}/>
            </div>

            <div className="element">
              <img src={bitmex}/>
            </div>

            <div clasName="element">
              <img src={kucoin}/>
            </div>
            
            <div className="element">
              <img src={okex}/>
            </div>
          </Swiper>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

export default ExchangeIntegrations;