import React from 'react';
import Faq from 'react-faq-component';
import { motion } from 'framer-motion';

let easing = [0.1575, 0.85, 0.42, 0.96];

const slideDownAnimation = {
  enter: { y: 0, opacity: 1, transition: { duration: 1.3, ease: easing } },
  exit: { y: -50, opacity: 0, transition: { duration: 1.3, ease: easing } }
}

const data = {
  rows: [
    {
      title: "What is a trading journal?",
      content: `A trading journal is similar to a diary that records your trading activity. It is 
      probably the most underrated and underused tool in the arsenal of a trader. 
      And you know why? Because data entry is a pain.`
    },
    {
      title: `Why should I use a trading journal?`,
      content: `The key to any successful trader is to have some way to measure, track and 
      analyse your performance in order to constantly find ways to improve. World class athletes 
      or football players keep detailed track of what makes them perform better.  A disciplined 
      trader is (usually) a profitable trader, or at least on the way of becoming one.`
    },
    {
      title: `How does MCJ work?`,
      content: `MyCryptoJournal lets you enter trades in 2 ways: manually or auto import from exchanges. 
      The information about the trade is divided into the basics and advanced view. The basic view is 
      ordered in a list and can easily be ordered and filtered. It contains info such as pair, quantity, 
      average entry price and P/L. It can also be expanded to show all the sub-trades associated with the 
      respective trade. You know, all those fractional mini-trades that composes a single trade. 
      The advanced view shows other important information besides the basic view. It includes RR, 
      reason for entering trades, setup names and even images for entry & exit trade setups.`
    },
    {
      title: `How to connect to your favourite exchange?`,
      content: `MCJ offers easy connectivity with your favourite exchanges. Just go to My Account and 
      connect the exchanges API. Remember, we only need READ ACCESS`
    },
    {
      title: `Why should you use MCJ?`,
      content: `First of all, if you’re going to use a trading journal (and we hope you are) you 
      have one of three options: excel (which is a pain to manage in the long term) or a professional 
      trading journal. MCJ, on top of to connecting to exchanges and easily importing trades, is 
      accessible from anywhere and has an easy to use interface.`
    }]
}

const styles = {
  rowTitleColor: "#525f7f",
};

const config = {
  animate: true
};

const FaqComponent = (props) => {
  const [lastYPos, setLastYPos] = React.useState(0);
  const [shouldShowSection, setShouldShowSection] = React.useState(false);

  React.useEffect(() => {
    function handleScroll() {
      const yPos = window.scrollY;

      let shouldShowSection;

      let sectionPos = document.querySelector("#faq").getBoundingClientRect();

      if (sectionPos.top < 800) {
        shouldShowSection = true;
      }

      setShouldShowSection(shouldShowSection);
      setLastYPos(yPos);
    }

    window.addEventListener("scroll", handleScroll, false);

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
    };
  }, [lastYPos]);

  return (
    <motion.div initial="exit" animate="enter" exit="exit" id="faq">
      <motion.div variants={slideDownAnimation}>
        <motion.div initial={slideDownAnimation.enter} animate={shouldShowSection ? slideDownAnimation.enter : slideDownAnimation.exit}>
          <h2>Have any questions?</h2>
          <Faq data={data} config={config} styles={styles}/>

          <div className="flex justify-center w-full mt-20">
            <a href="https://app.mycryptojournal.io/register"><button className="btn btn-primary inline mx-auto">Sign Up</button></a>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

export default FaqComponent;